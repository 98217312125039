import { useEffect, useState } from 'react';

import { getLSItem, setLSItem } from '../helpers';

const useDarkLightMode = () => {
    const [mode, setMode] = useState('automatic');
    const [systemMode, setSystemMode] = useState('automatic');
    const [userMode, setUserMode] = useState(getLSItem('userMode') || 'automatic');

    useEffect(() => {
        const query = '(prefers-color-scheme: dark)';
        setSystemMode(window.matchMedia(query).matches ? 'dark' : 'light');
        const listener = e => setSystemMode(e.matches ? 'dark' : 'light');
        window?.matchMedia(query)?.addEventListener('change', listener);
    }, []); // eslint-disable-line

    useEffect(() => {
        setLSItem('userMode', userMode);
    }, [userMode]); // eslint-disable-line

    useEffect(() => {
        setMode(userMode === 'automatic' ? systemMode : userMode);
    }, [userMode, systemMode]); // eslint-disable-line

    useEffect(() => {
        document.documentElement.setAttribute('mode', mode);
        const element = document.querySelector('meta[name="theme-color"]');
        !!element && element.setAttribute('content', mode === 'dark' ? '#0c0e11' : '#ffffff');
    }, [mode]);

    const actions = [
        {
            id: 'automatic',
            name: 'Follow System',
            icon: 'icon-loader',
            onClick: () => setUserMode('automatic'),
            selected: userMode === 'automatic',
        },
        {
            id: 'light',
            name: 'Light',
            icon: 'icon-sun',
            onClick: () => setUserMode('light'),
            selected: userMode === 'light',
        },
        {
            id: 'dark',
            name: 'Dark',
            icon: 'icon-moon',
            onClick: () => setUserMode('dark'),
            selected: userMode === 'dark',
        },
    ];

    return ({
        mode,
        actions,
        userMode,
        setUserMode,
    })
}

export default useDarkLightMode;
