import React from 'react';

import Icon from '../icon';
import Popover from '../popover';
import DropDown from '../dropDown';

import useDevice from '../../hooks/useDevice';
import useDarkLightMode from '../../hooks/useDarkLightMode';

const AppearanceSwitcher = () => {
    const {
        actions,
        userMode,
        setUserMode,
    } = useDarkLightMode();

    const selected = actions.find(item => item.id === userMode);

    const {
        isTablet,
    } = useDevice();

    return isTablet ? (
        <DropDown
            width={220}
            data={actions}
            position='top'
            label='Appearance'
            defaultSelected={selected?.id}
            onChange={val => setUserMode(val)}
        />
    ) : (
        <Popover
            menu={[
                {
                    title: true,
                    name: 'Appearance',
                },
                {
                    divider: true,
                },
                ...actions,
            ]}
        >
            <button
                className='blank-button'
                aria-label='Change appearance'
            >
                <Icon
                    type='icon-loader'
                />
            </button>
        </Popover>
    );
};

export default AppearanceSwitcher;
