import { useEffect, useState } from 'react';

import { isBrowser } from '../helpers';

const useDevice = () => {
    const conditionMobile = () => isBrowser() && document.documentElement.clientWidth <= 650;
    const conditionTablet = () => isBrowser() && document.documentElement.clientWidth <= 900;

    const [isMobile, setIsMobile] = useState(conditionMobile());
    const [isTablet, setIsTablet] = useState(conditionTablet());

    const checkForDevice = () => {
        setIsMobile(conditionMobile());
        setIsTablet(conditionTablet());
    };

    useEffect(() => {
        checkForDevice();

        window.addEventListener('resize', checkForDevice);

        return () => {
            window.removeEventListener('resize', checkForDevice);
        };
    }, []); // eslint-disable-line

    return {
        isMobile,
        isTablet,
    };
};

export default useDevice;
