import { conditionalSpread, rem } from 'clyne-core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './icomoon/style.css';

import './index.scss';

const Icon = props => {
    const {
        type,
        size,
        color,
        className,
    } = props;

    return (
        <i
            className={classNames(
                'icon',
                type,
                className,
            )}
            style={{
                ...conditionalSpread({
                    '--size': `var(--icon-external-size, ${rem(size)})`,
                }, size),
                ...conditionalSpread({
                    color,
                }, color),
            }}
        />
    );
};

Icon.defaultProps = {
    size: 24,
};

Icon.propTypes = {
    type: PropTypes.string,
    size: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string,
};

export default Icon;
