import { useEffect, useState } from 'react';

const usePopoverInputFocus = ref => {
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        const element = ref?.current?.querySelector('input');

        if (element) {
            element.addEventListener('focus', () => setFocused(true));
            element.addEventListener('blur', () => setFocused(false));
        }
        return () => {
            if (element) {
                element.removeEventListener('focus', () => setFocused(true));
                element.removeEventListener('blur', () => setFocused(false));
            }
        };
    }, [ref.current]); // eslint-disable-line

    return { focused };
};

export default usePopoverInputFocus;
