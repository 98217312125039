import { conditionalSpread, isExternalURL } from 'clyne-core';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Button from '../button';

import useDevice from '../../hooks/useDevice';

const StickyCTA = () => {
    const queryData = useStaticQuery(
        graphql`
            query HeaderContent {
                allContentfulHeader {
                    edges {
                        node {
                            callToAction {
                                url
                                icon
                                name
                            }
                        }
                    }
                }
            }
        `
    );

    const data = queryData?.allContentfulHeader?.edges?.[0]?.node;

    const {
        isTablet,
    } = useDevice();

    return !!data?.callToAction && (
        <>
            {!isTablet && (
                <div className='h-divider' />
            )}
            <Button
                {...conditionalSpread({
                    target: '_blank',
                }, isExternalURL(data.callToAction.url))}
                url={data.callToAction.url}
                itemsDirection='end'
                icon={{
                    size: 20,
                    type: data.callToAction.icon,
                }}
            >
                {data.callToAction.name}
            </Button>
        </>
    )
}

export default StickyCTA;
