import { conditionalSpread, isExternalURL } from 'clyne-core';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import To from '../to';

import './index.scss';

const currentYear = new Date().getFullYear();

const Footer = () => {
    const queryData = useStaticQuery(
        graphql`
            query FooterContent {
                allContentfulFooter {
                    edges {
                        node {
                            copyright
                            links {
                                id
                                url
                                title
                            }
                        }
                    }
                }
            }
        `
    );

    const data = queryData?.allContentfulFooter?.edges?.[0]?.node;

    return (
        <footer>
            {!!data?.copyright && (
                <p>{data.copyright.replace('_YEAR_', currentYear)}</p>
            )}
            {!!data?.links?.length && (
                <div className='footer-links'>
                    {data.links.map(link => (
                        <To
                            key={link.id}
                            url={link.url}
                            {...conditionalSpread({
                                target: '_blank',
                            }, isExternalURL(link.url))}
                        >
                            {link.title}
                        </To>
                    ))}
                </div>
            )}
        </footer>
    );
};

export default Footer;
