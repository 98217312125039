import { useEffect, useState } from 'react';

import { isBrowser } from '../helpers';

const useWindowResize = () => {
    const [screenWidth, setScreenWidth] = useState(isBrowser() ? window.innerWidth : 0);
    const [screenHeight, setScreenHeight] = useState(isBrowser() ? window.innerHeight : 0);

    useEffect(() => {
        if (isBrowser()) {
            const resize = () => {
                setScreenHeight(window.innerHeight);
                setScreenWidth(window.innerWidth);
            };
            window.addEventListener('resize', resize);
            return () => {
                window.removeEventListener('resize', resize);
            };
        }
    }, []);

    return { screenWidth, screenHeight };
};

export default useWindowResize;
