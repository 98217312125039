import React, { Fragment } from 'react';
import classNames from 'classnames';

import To from '../to';
import Logo from '../logo';
import Icon from '../icon';
import LeadBar from '../leadBar';
import Popover from '../popover';
import StickyCTA from '../stickyCTA';
import SearchInput from '../searchInput';
import VersionSwitcher from '../versionSwitcher';
import AppearanceSwitcher from '../appearanceSwitcher';

import useDevice from '../../hooks/useDevice';
import useSiteMetadata from '../../hooks/useSiteMetadata';
import useSharedContext from '../../hooks/useSharedContext';

import './index.scss';

const Header = () => {
    const { metadata } = useSiteMetadata();

    const {
        isTablet,
    } = useDevice();

    const {
        navigationOpened,
        setNavigationOpened,
    } = useSharedContext();

    const logo = (
        <Logo />
    );

    const products = [
        {
            title: 'QRNG',
            url: 'https://qrng.quantumcomputinginc.com',
            subTitle: 'Raw qudit QRNs without randomness extraction or amplification',
        },
        {
            title: 'Qatalyst',
            url: 'https://qatalyst.quantumcomputinginc.com',
            subTitle: 'Quantum optimization service on cloud for solving various problems',
        },
    ];

    const logoType = (
        <div className='logo-type-holder'>
            <To
                url='/'
                className='logo-type'
            >
                {metadata.name}
            </To>
            <Popover
                position='bottom'
                content={({ setOpenedState }) => (
                    <div className='product-switcher-content'>
                        {products.map((product, index) => {
                            const active = product.title.toLowerCase() === process.env.GATSBY_NAME.toLowerCase();

                            return (
                                <Fragment key={index}>
                                    {!!index && (
                                        <div className='v-divider' />
                                    )}
                                    <div
                                        className={classNames(
                                            'product-switcher-item',
                                            {
                                                active,
                                            }
                                        )}
                                    >
                                        <h4>{product.title}</h4>
                                        <p>{product.subTitle}</p>
                                        <To
                                            className='absolute-splash'
                                            url={active ? '/' : product.url}
                                            onClick={() => setOpenedState(false)}
                                        />
                                    </div>
                                </Fragment>
                            );
                        })}
                    </div>
                )}
            >
                <button
                    className='blank-button'
                    aria-label='Change selected product'
                >
                    <Icon
                        size={18}
                        type='icon-chevron-down'
                    />
                </button>
            </Popover>
        </div>
    );

    return (
        <header
            className={classNames(
                {
                    'navigation-opened': navigationOpened,
                }
            )}
        >
            {isTablet ? (
                <>
                    <LeadBar />
                    <ul className='h-tablet-contents'>
                        <li>
                            {logo}
                        </li>
                        <li>
                            {logoType}
                        </li>
                        <li>
                            <button
                                aria-label='Toggle navigation menu'
                                onClick={() => setNavigationOpened(val => !val)}
                                className={classNames(
                                    'navigation-toggle',
                                    {
                                        'active': navigationOpened,
                                    }
                                )}
                            />
                        </li>
                    </ul>
                </>
            ) : (
                <>
                    <div className='h-left-content'>
                        {logo}
                        {logoType}
                        <SearchInput />
                    </div>
                    <div className='h-right-content'>
                        <VersionSwitcher />
                        <AppearanceSwitcher />
                        <StickyCTA />
                    </div>
                </>
            )}
        </header>
    );
};

export default Header;
