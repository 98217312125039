import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

import { sortTags } from '../helpers';

const useVersions = () => {
    const tags = useStaticQuery(graphql`
        query {
            allContentfulTag {
                nodes {
                    contentful_id
                }
            }
        }
    `);

    const location = useLocation();

    const allTags = sortTags(tags.allContentfulTag.nodes);

    const latestVersion = allTags[0].contentful_id;

    const selectedVersion = allTags?.find(item => item?.contentful_id === location.pathname?.split('/')?.[1])?.contentful_id || latestVersion;

    return {
        latestVersion,
        selectedVersion,
        availableVersions: allTags,
        isOnLatestVersion: latestVersion === selectedVersion,
    };
};

export default useVersions;
