import { default as ReactCollapse } from '@kunukn/react-collapse';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from '@reach/router';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import To from '../to';
import Icon from '../icon';

import { preSortTreeView } from '../../helpers';

import useSharedContext from '../../hooks/useSharedContext';

import './index.scss';

const SubNavigation = props => {
    const {
        content,
    } = props;

    const {
        setNavigationOpened,
    } = useSharedContext();

    const location = useLocation();

    const data = useMemo(() => content, [JSON.stringify(content)]); // eslint-disable-line

    const [active, setActive] = useState(false);
    const [localData, setLocalData] = useState(null);
    const [openedMenus, setOpenedMenus] = useState([]);
    const [activeMenus, setActiveMenus] = useState([]);

    useEffect(() => {
        setActiveMenus([]);
        const checkForSlugMatch = item => {
            if (location.pathname.includes(item.slug)) {
                setActiveMenus(val => [...val, item.id]);
                setOpenedMenus(val => [...val, item.id]);
            }
            item.children && item.children.forEach(item => checkForSlugMatch(item));
        };

        data?.forEach(item => checkForSlugMatch(item));
    }, [location.pathname, data]); // eslint-disable-line

    useEffect(() => {
        !!data && setLocalData(data);
        setActive(!!data);
    }, [data]);

    const treeRenderer = (routes, level) => (level ? preSortTreeView(routes) : routes)?.map(route => {
        const opened = openedMenus.includes(route.id);
        const active = activeMenus.includes(route.id);

        const handleClick = () => {
            setOpenedMenus(val => val.includes(route.id) ? val.filter(i => i !== route.id) : [...val, route.id]);
        };

        const branch = (
            <>
                {(route.icon && !level) && (
                    <Icon
                        size={20}
                        type={route.icon}
                    />
                )}
                <span className='text-ellipsis'>{route.title}</span>
                {route.children && (
                    <Icon
                        size={20}
                        className='status-icon'
                        type={level ? (opened ? 'icon-minus' : 'icon-plus') : 'icon-chevron-down'}
                    />
                )}
            </>
        );

        return (
            <div
                key={route.id}
                className={`level-${level}`}
            >
                {route.children ? (
                    <div className='sub-navigation-sub-holder'>
                        <button
                            onClick={handleClick}
                            className={classNames(
                                'sub-navigation-item',
                                {
                                    opened,
                                    active,
                                }
                            )}
                        >
                            {branch}
                        </button>
                        <ReactCollapse isOpen={opened}>
                            <div className='sub-navigation-sub'>
                                {treeRenderer(route.children, level + 1)}
                            </div>
                        </ReactCollapse>
                    </div>
                ) : (
                    <To
                        url={route.slug}
                        className='sub-navigation-item'
                        onClick={() => setNavigationOpened(false)}
                    >
                        {branch}
                    </To>
                )}
            </div>
        );
    });

    return (
        <div
            className={classNames(
                'sub-navigation',
                {
                    'active': active,
                }
            )}
            onTransitionEnd={e => {
                if (e.target.classList.contains('sub-navigation') && !active) {
                    setOpenedMenus([]);
                    setActiveMenus([]);
                }
            }}
        >
            <div className='sub-navigation-content'>
                {treeRenderer(localData, 0)}
            </div>
        </div>
    );
};

SubNavigation.propTypes = {
    content: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        icon: PropTypes.string,
        slug: PropTypes.string,
        title: PropTypes.string,
        children: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.string,
            slug: PropTypes.string,
            title: PropTypes.string,
            children: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.string,
                icon: PropTypes.string,
                slug: PropTypes.string,
                title: PropTypes.string,
            })),
        })),
    })),
};

export default SubNavigation;
