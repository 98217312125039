import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../icon';

import useDevice from '../../hooks/useDevice';
import useSharedContext from '../../hooks/useSharedContext';

import { isBrowser } from '../../helpers';

import './index.scss';

const Search = React.forwardRef(function Search(props, ref) {
    const {
        value,
        onBlur,
        onFocus,
        onClick,
        onChange,
        readOnly,
        autoFocus,
        onKeyDown,
        appearance,
        placeholder,
    } = props;

    const sharedProps = {
        value,
        onBlur,
        onClick,
        onFocus,
        onChange,
        readOnly,
        autoFocus,
        onKeyDown,
        type: 'search',
        'aria-label': placeholder,
    };

    const {
        isMobile,
    } = useDevice();

    const {
        setNavigationOpened,
    } = useSharedContext();

    const inputRef = useRef(null);

    useEffect(() => {
        const handler = e => {
            if (e.key === '/' && inputRef.current && !isMobile) {
                setNavigationOpened(true);
                inputRef.current.focus();
            }
        };

        isBrowser() && window.addEventListener('keyup', handler);

        return () => {
            window.removeEventListener('keyup', handler);
        };
    }, [isMobile]); // eslint-disable-line

    return (
        <div
            ref={ref}
            className={classNames(
                `search-input-holder`,
                `a-${appearance}`,
                {
                    'filled': value,
                }
            )}
        >
            {appearance === 'inline' ? (
                <>
                    <div className='inline-search-holder'>
                        <Icon
                            size={20}
                            type='icon-search'
                        />
                        <input
                            placeholder={placeholder}
                            {...sharedProps}
                        />
                    </div>
                    <div className='inline-search-line' />
                </>
            ) : (
                <>
                    <Icon
                        size={20}
                        type='icon-search'
                    />
                    <input
                        ref={inputRef}
                        placeholder={placeholder}
                        {...sharedProps}
                    />
                    {!isMobile && (
                        <small>/</small>
                    )}
                </>
            )}
        </div>
    );
});

Search.defaultProps = {
    appearance: 'default',
};

Search.propTypes = {
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onKeyDown: PropTypes.func,
    appearance: PropTypes.oneOf(['default', 'inline']),
    placeholder: PropTypes.any,
};

export default Search;
