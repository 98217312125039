import PropTypes from 'prop-types';
import React from 'react';

import MenuItem from '../menuItem';

import './index.scss';

const Menu = props => {
    const {
        data,
        closePopover,
    } = props;

    return (
        <div className='menu-items-wrapper'>
            {data.map((item, index) => item.divider ? (
                <div
                    key={index}
                    className='v-divider'
                />
            ) : (
                <MenuItem
                    url={item.url}
                    icon={item.icon}
                    name={item.name}
                    title={item.title}
                    selected={item.selected}
                    key={`${index}${item.name}`}
                    highlighted={item.highlighted}
                    onClick={e => {
                        !!item.onClick && item.onClick(e);
                        !!closePopover && closePopover();
                    }}
                />
            ))}
        </div>
    );
};

Menu.propTypes = {
    data: PropTypes.array,
    closePopover: PropTypes.func,
};

export default Menu;
