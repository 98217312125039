import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../icon';

import './index.scss';

const Option = props => {
    const {
        name,
        size,
        check,
        onClick,
        selected,
        information,
        highlighted,
        setItemHeight,
    } = props;

    const optionRef = useRef(null);

    useEffect(() => {
        optionRef.current && setItemHeight && setItemHeight(optionRef.current.clientHeight);
    }, [setItemHeight]);

    return (
        <ul
            ref={optionRef}
            onClick={onClick}
            role='presentation'
            className={classNames(
                `option-holder`,
                `s-${size}`,
                {
                    selected,
                    highlighted,
                    'c-end': check,
                }
            )}
        >
            <li className='o-content'>
                <div className='text-ellipsis'>
                    {name}
                </div>
                {information && (
                    <div className='o-information text-ellipsis'>{information}</div>
                )}
            </li>
            {check && (
                <li className='o-c-holder'>
                    <div className='option-check'>
                        <Icon
                            size={22}
                            type='icon-check'
                        />
                    </div>
                </li>
            )}
        </ul>
    );
};

Option.defaultProps = {
    size: 'big',
};

Option.propTypes = {
    name: PropTypes.any,
    size: PropTypes.oneOf(['medium', 'big']),
    check: PropTypes.bool,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    information: PropTypes.any,
    highlighted: PropTypes.bool,
    setItemHeight: PropTypes.func,
};

export default Option;
