import { toProps } from 'clyne-core/props';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import To from '../to';
import Icon from '../icon';

import './index.scss';

const Button = props => {
    const {
        url,
        size,
        icon,
        color,
        target,
        onClick,
        children,
        appearance,
        itemsDirection,
    } = props;

    const sharedProps = {
        onClick,
        className: classNames(
            'button',
            `s-${size}`,
            `c-${color}`,
            `a-${appearance}`,
            `id-${itemsDirection}`,
        ),
    };

    const content = (
        <>
            {!!icon && (
                <Icon
                    {...icon}
                />
            )}
            {!!children && (
                <span className='text-ellipsis'>{children}</span>
            )}
        </>
    );

    return url ? (
        <To
            url={url}
            target={target}
            {...sharedProps}
        >
            {content}
        </To>
    ) : (
        <button
            type='button'
            {...sharedProps}
        >
            {content}
        </button>
    );
};

Button.defaultProps = {
    size: 'default',
    color: 'contrast',
    target: toProps.target[0],
    appearance: 'fill',
    itemsDirection: 'start',
};

Button.propTypes = {
    url: PropTypes.string,
    size: PropTypes.oneOf(['small', 'default', 'big']),
    icon: PropTypes.shape(Icon.propTypes),
    color: PropTypes.oneOf(['contrast', 'secondary']),
    target: PropTypes.oneOf(toProps.target),
    onClick: PropTypes.func,
    children: PropTypes.any,
    appearance: PropTypes.oneOf(['fill', 'outline']),
    itemsDirection: PropTypes.oneOf(['start', 'end']),
};

export default Button;
