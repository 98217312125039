import { conditionalSpread } from 'clyne-core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import To from '../to';
import Icon from '../icon';

import './index.scss';

const MenuItem = props => {
    const {
        url,
        name,
        icon,
        title,
        onClick,
        selected,
        highlighted,
    } = props;

    return (
        <div
            onClick={onClick}
            role='presentation'
            className={classNames(
                `menu-item`,
                {
                    title,
                    selected,
                    highlighted,
                },
            )}
        >
            <ul className='mi-content'>
                {!!icon && (
                    <li className='f-fit'>
                        <Icon
                            size={22}
                            {...conditionalSpread({
                                type: icon,
                            }, typeof icon === 'string')}
                            {...icon}
                        />
                    </li>
                )}
                <li className='f-auto'>
                    <div className='text-ellipsis'>
                        {name}
                    </div>
                </li>
                {(!!selected) && (
                    <li className='f-fit'>
                        <Icon
                            size={20}
                            type='icon-check'
                        />
                    </li>
                )}
            </ul>
            {!!url && (
                <To
                    url={url}
                    className='absolute-splash'
                />
            )}
        </div>
    );
};

MenuItem.propTypes = {
    url: PropTypes.string,
    name: PropTypes.any,
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    title: PropTypes.bool,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    highlighted: PropTypes.bool,
};

export default MenuItem;
