import { default as ReactCollapse } from '@kunukn/react-collapse';
import { conditionalSpread, isExternalURL } from 'clyne-core';
import React, { Fragment, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import classNames from 'classnames';

import To from '../to';
import Icon from '../icon';
import StickyCTA from '../stickyCTA';
import SearchInput from '../searchInput';
import SubNavigation from '../subNavigation';
import VersionSwitcher from '../versionSwitcher';
import AppearanceSwitcher from '../appearanceSwitcher';

import useDevice from '../../hooks/useDevice';
import useSharedContext from '../../hooks/useSharedContext';

import { getExample, pageURLFormatter } from '../../helpers';

import './index.scss';

const Aside = props => {
    const {
        data,
    } = props;

    const queryData = useStaticQuery(
        graphql`
            query Navigation {
                allContentfulSidebar {
                    edges {
                        node {
                            id
                            title
                            items {
                                __typename
                                ... on ContentfulCategory {
                                    id
                                    icon
                                    name
                                    slug
                                }
                                ... on ContentfulLink {
                                    id
                                    url
                                    icon
                                    title
                                }
                                ... on ContentfulPage {
                                    id
                                    icon
                                    title
                                    category {
                                        slug
                                        icon
                                        category {
                                            slug
                                            icon
                                            category {
                                                slug
                                                icon
                                            }
                                        }
                                    }
                                    metadata {
                                        tags {
                                            contentful_id
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    const {
        latestVersion,
        navigationOpened,
        isOnLatestVersion,
        setNavigationOpened,
        versionSwitcherActive,
    } = useSharedContext();

    const {
        isTablet,
    } = useDevice();

    const location = useLocation();

    const [selectedNavId, setSelectedNavId] = useState('');

    const filteredTree = data?.filter(item => item.title !== getExample());

    const sidebarData = queryData?.allContentfulSidebar?.edges?.filter(item => item?.node?.title !== getExample())?.flatMap(item => item?.node?.items).map(item => ({
        slug: item?.__typename === '' ? pageURLFormatter(item, latestVersion) : item?.slug,
        url: item?.url,
        id: item?.id,
        icon: item?.icon,
        title: item?.name || item?.title,
        item,
    })).map(item => filteredTree?.find(i => i.id === item.id || i.originalId === item.id) ?? item);

    const routes = [
        {
            slug: '/',
            id: 'main',
            icon: 'icon-home-2',
            title: 'Main',
        },
        ...(sidebarData || filteredTree),
    ];

    useEffect(() => {
        setSelectedNavId('');
        const possibleSlug = location.pathname.split('/')?.[1];
        const possibleRoute = routes.find(route => route.slug === `/${possibleSlug}`)?.id;
        possibleRoute && setSelectedNavId(possibleRoute);
    }, [location.pathname]); // eslint-disable-line

    const currentSubNavigation = routes.find(route => route.id === selectedNavId)?.children;

    const contentRenderer = route => (
        <>
            <Icon
                size={isTablet ? 24 : 28}
                type={route.icon}
            />
            <span>{route.title}</span>
            {!!route.children && isTablet && (
                <Icon
                    size={20}
                    type='icon-chevron-down'
                />
            )}
        </>
    );

    const aside = (
        <aside>
            <nav>
                {routes.map(route => {
                    const possibleSlug = location.pathname.split('/')?.[1];
                    const active = route.slug === `/${possibleSlug}`;
                    const selected = selectedNavId === route.id;

                    const className = classNames(
                        {
                            active,
                            selected,
                        }
                    );

                    const item = route.children ? (
                        <button
                            className={className}
                            onClick={() => setSelectedNavId(val => val === route.id ? '' : route.id)}
                        >
                            {contentRenderer(route)}
                        </button>
                    ) : (
                        <To
                            url={route.slug || route.url}
                            className={className}
                            onClick={() => {
                                setSelectedNavId('');
                                setNavigationOpened(false);
                            }}
                            {...conditionalSpread({
                                target: '_blank',
                            }, route?.url && isExternalURL(route?.url))}
                        >
                            {contentRenderer(route)}
                        </To>
                    );

                    return (
                        <Fragment key={route.id}>
                            {isTablet ? (
                                <div>
                                    {item}
                                    <ReactCollapse isOpen={selected}>
                                        <SubNavigation content={route.children} />
                                    </ReactCollapse>
                                </div>
                            ) : item}
                        </Fragment>
                    );
                })}
            </nav>
        </aside>
    );

    return isTablet ? (
        <div
            className={classNames(
                'touch-navigation-holder',
                {
                    'lead-bar-visible': !isOnLatestVersion,
                    'active': navigationOpened,
                }
            )}
        >
            <div className='touch-navigation-content'>
                <SearchInput />
                {aside}
                <div className='v-divider' />
                <ul className='tn-footer-holder'>
                    <li>
                        <StickyCTA />
                    </li>
                    <li>
                        <ul className='tn-footer-inline-actions'>
                            {versionSwitcherActive && (
                                <>
                                    <li>
                                        <VersionSwitcher />
                                    </li>
                                    <li>
                                        <div className='h-divider' />
                                    </li>
                                </>
                            )}
                            <li>
                                <AppearanceSwitcher />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    ) : (
        <>
            {aside}
            <SubNavigation content={currentSubNavigation} />
        </>
    );
};

export default Aside;
