import { graphql, useStaticQuery } from 'gatsby';

const useSiteMetadata = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    name
                    title
                    author
                    siteUrl
                    description
                }
            }
        }
    `);

    return ({
        metadata: data.site.siteMetadata,
    })
};

export default useSiteMetadata;
