import React from 'react';

import To from '../to';

import useDevice from '../../hooks/useDevice';
import useSharedContext from '../../hooks/useSharedContext';

import './index.scss';

const LeadBar = () => {
    const {
        isTablet,
    } = useDevice();

    const {
        selectedVersion,
        isOnLatestVersion,
    } = useSharedContext();

    const version = (
        <span>v{selectedVersion}</span>
    );

    const cta = (
        <To>latest version</To>
    );

    return !isOnLatestVersion && (
        <div className='lead-bar-holder'>
            {isTablet ? (
                <p>This is {version} documentation, see the {cta}</p>
            ) : (
                <p>You are currently viewing {version} documentation, some things may not work as expected, see the {cta}</p>
            )}
        </div>
    );
};

export default LeadBar;
