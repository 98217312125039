import { conditionalSpread } from 'clyne-core';
import React from 'react';

import useDevice from '../../hooks/useDevice';
import useSiteMetadata from '../../hooks/useSiteMetadata';
import useSharedContext from '../../hooks/useSharedContext';

import DropDown from '../dropDown';

import { isBrowser } from '../../helpers';

const VersionSwitcher = () => {
    const { metadata } = useSiteMetadata();

    const {
        isTablet,
    } = useDevice();

    const {
        latestVersion,
        selectedVersion,
        availableVersions,
        versionSwitcherActive,
    } = useSharedContext();

    return !!availableVersions?.length && versionSwitcherActive && (
        <DropDown
            data={availableVersions.map(item => ({
                name: `${isTablet ? `${metadata.name} ` : ''}v${item?.contentful_id}`,
                id: item?.contentful_id,
            }))}
            width={isTablet ? 220 : 140}
            label={isTablet ? 'Version' : `${metadata.name} Version`}
            defaultSelected={selectedVersion}
            onChange={val => isBrowser() && (window.location.href = val === latestVersion ? '/' : `/${val}/`)}
            {...conditionalSpread({
                position: 'top',
            }, isTablet)}
        />
    );
};

export default VersionSwitcher;
