import React, { useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';

import Aside from '../components/aside';
import Header from '../components/header';
import Footer from '../components/footer';
import LeadBar from '../components/leadBar';

import SharedContext from '../contexts/sharedContext';

import { formatCriticalData, isBrowser } from '../helpers';

import useDevice from '../hooks/useDevice';
import useVersions from '../hooks/useVersions';
import useWindowResize from '../hooks/useWindowResize';

import '../assets/scss/main.scss';
import '../assets/fonts/halvar/style.css';
import '../assets/fonts/proxima/style.css';
import './index.scss';

const Layout = props => {
    const {
        children,
    } = props;

    const criticalData = formatCriticalData(useStaticQuery(graphql`
        query {
            allContentfulCategory {
                edges {
                    node {
                        ...CategoryFragment
                        subCategories {
                            ...SubCategoryFragment
                            subCategories {
                                ...SubCategoryFragment
                                subCategories {
                                    ...SubCategoryFragment
                                    subCategories {
                                        ...SubCategoryFragment
                                    }
                                }
                            }
                        }
                    }
                }
            }
            allContentfulPage {
                edges {
                    node {
                        id
                        icon
                        title
                        category {
                            id
                        }
                        metadata {
                            tags {
                                contentful_id
                            }
                        }
                    }
                }
            }
            allContentfulTag {
                nodes {
                    contentful_id
                }
            }
            allContentfulHomepage {
                edges {
                    node {
                        id
                        metadata {
                            tags {
                                contentful_id
                            }
                        }
                    }
                }
            }
        }

        fragment CategoryFragment on ContentfulCategory {
            id
            name
            slug
            icon
            page {
                id
                title
                metadata {
                    tags {
                        contentful_id
                    }
                }
            }
        }

        fragment SubCategoryFragment on ContentfulCategory {
            ...CategoryFragment
            subCategories {
                ...CategoryFragment
            }
        }
    `));

    const {
        screenHeight,
    } = useWindowResize();

    const {
        latestVersion,
        selectedVersion,
        availableVersions,
        isOnLatestVersion,
    } = useVersions();

    const {
        isMobile,
        isTablet,
    } = useDevice();

    const [searchValue, setSearchValue] = useState('');
    const [currentHeading, setCurrentHeading] = useState('');
    const [navigationOpened, setNavigationOpened] = useState(false);

    const location = useLocation();

    const mainRef = useRef(null);

    useEffect(() => {
        isBrowser() && document.documentElement.style.setProperty('--vh', screenHeight + 'px');
    }, [screenHeight, isMobile]);

    useEffect(() => {
        isBrowser() && document.documentElement.style.setProperty('--vih', window.innerHeight + 'px');
    }, [isMobile]);

    useEffect(() => {
        mainRef.current && (mainRef.current.scrollTop = 0);
    }, [location.pathname]);

    const contextValues = {
        searchValue,
        criticalData,
        latestVersion,
        setSearchValue,
        currentHeading,
        selectedVersion,
        navigationOpened,
        setCurrentHeading,
        availableVersions,
        isOnLatestVersion,
        setNavigationOpened,
        versionSwitcherActive: availableVersions.length > 1,
    };

    return (
        <SharedContext.Provider value={contextValues}>
            {!isTablet && (
                <LeadBar />
            )}
            <Header />
            <Aside data={criticalData?.categories} />
            <main ref={mainRef}>
                <section>
                    {children}
                </section>
                <Footer />
            </main>
        </SharedContext.Provider>
    );
};

Layout.propTypes = {
    children: PropTypes.any,
};

export default Layout;
