import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import SearchImage from './media/search.inline.svg';

import './index.scss';

const NoData = props => {
    const {
        size,
        title,
        subTitle,
    } = props;

    return (
        <div
            className={classNames(
                `no-data-holder`,
                `s-${size}`,
            )}
        >
            <SearchImage />
            {!!title && (
                <div className='nd-title-holder'>
                    {title}
                </div>
            )}
            {!!subTitle && (
                <div className='nd-sub-title-holder'>
                    {subTitle}
                </div>
            )}
        </div>
    );
};

NoData.defaultProps = {
    size: 'big',
};

NoData.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'big']),
    title: PropTypes.any,
    subTitle: PropTypes.any,
};

export default NoData;
