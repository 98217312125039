const isExternalURL = url => !!`${url}`.match(/(https?:\/\/\S+)/g) || `${url}`.includes('mailto:') || `${url}`.includes('tel:');

const conditionalSpread = (spread, condition) => !!condition ? spread : (Array.isArray(spread) ? [] : {});

const slugify = text => text ? text.toString().normalize('NFKD').toLowerCase().trim().replace(/\s+/g, '-').replace(/[^\w-]+/g, '').replace(/_/g, '-').replace(/-+/g, '-').replace(/-$/g, '') : '';

const tagToVersion = str => Number(str.replaceAll('.', ''));

const isBrowser = () => typeof window !== 'undefined';

const sortTags = tags => tags?.sort((a, b) => tagToVersion(b?.contentful_id) - tagToVersion(a?.contentful_id));

const pageURLFormatter = (page, latestVersion, slug) => {
    if (page?.url && isExternalURL(page?.url)) {
        return page.url;
    }

    const tag = (page?.metadata?.tags?.length ? sortTags(page.metadata.tags) : [latestVersion])[0];

    const url = [
        (tag?.contentful_id === latestVersion?.contentful_id || tag?.contentful_id === latestVersion || tag === latestVersion) ? '' : tag?.contentful_id,
        ...(slug ? slug : [
            page?.category?.category?.[0]?.category?.[0]?.slug,
            page?.category?.category?.[0]?.slug,
            page?.category?.slug,
        ]),
        slugify(page?.title),
    ].filter(i => i);

    return `/${[...new Set(url)].join('/')}`;
};

const findDeep = (data, indexStack, index = 0) => (index < indexStack.length ? findDeep(data[indexStack[index]].children, indexStack, index + 1) : data);

const getLSItem = key => {
    if (isBrowser()) {
        const value = window.localStorage.getItem(key);
        if (!value) {
            return null;
        }

        return value;
    }

    return null;
};

const setLSItem = (key, value) => isBrowser() && window.localStorage.setItem(key, value);

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const getExample = () => 'DO NOT DELETE';

const removeDuplicates = arr => [...new Set(arr || [])].filter(i => i);

const slugFormatter = (page, slug = [], latestVersion) => {
    const tag = (page?.metadata?.tags?.length ? sortTags(page.metadata.tags) : [latestVersion])[0];

    const url = [
        (tag?.contentful_id === latestVersion?.contentful_id || tag?.contentful_id === latestVersion || tag === latestVersion) ? '' : tag?.contentful_id,
        ...slug,
        slugify(page?.title),
    ];

    return removeDuplicates(url).join('/');
};

const sortAlphabetically = arr => Array.isArray(arr) ? arr.sort((a, b) => {
    if (a?.title < b?.title) {
        return -1;
    }
    if (a?.title > b?.title) {
        return 1;
    }
    return 0;
}) : arr;

const preSortTreeView = arr => Array.isArray(arr) ? [...sortAlphabetically(arr.filter(item => item?.children) || []), ...sortAlphabetically(arr.filter(item => !item?.children) || [])] : arr;

const slugToUrl = slug => slug?.[0] === '/' ? slug : `/${slug}`;

const constructCategories = (arr = [], parentSlug = '', latestVersion) => arr.filter(category => category?.node?.page?.length || category?.node?.subCategories?.length).map(category => {
    const slug = [...new Set([parentSlug, category.node.slug])].filter(i => i);

    return ({
        id: category.node.id,
        slug: slugToUrl(removeDuplicates(slug).join('/')),
        icon: category.node.icon,
        title: category.node.name,
        ...conditionalSpread({
            page: category?.node?.page?.map(page => ({
                ...page,
                slug: slugFormatter(page, slug, latestVersion),
            })),
        }, category?.node?.page?.length),
        ...conditionalSpread({
            subCategories: constructCategories(category?.node?.subCategories?.map(category => ({
                node: category,
            })), slugToUrl(removeDuplicates(slug).join('/')), latestVersion),
        }, category?.node?.subCategories?.length),
    });
});

const flattenCategories = arr => arr.reduce((prev, current) => ([...prev, current, ...(current?.subCategories ? flattenCategories(current.subCategories) : [])]), []);

const formatCategoryTree = arr => arr?.map(item => {
    const children = [
        ...(item?.page?.map(page => ({
            ...page,
            slug: slugToUrl(page?.slug),
        })) || []),
        ...(formatCategoryTree(item?.subCategories) || []),
    ];

    const shouldReplace = children.length === 1;

    const getValue = key => shouldReplace ? (children?.[0]?.[key] || item?.[key]) : item?.[key];

    return ({
        originalId: item?.id,
        id: getValue('id'),
        icon: getValue('icon'),
        title: getValue('title'),
        slug: slugToUrl(getValue('slug')),
        ...conditionalSpread({
            children,
        }, children.length && children.length > 1),
    });
})?.sort((a, b) => {
    const textA = a?.title?.toUpperCase();
    const textB = b?.title?.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
});

const flattenCategoryTree = data => {
    return data.reduce((r, { children, ...rest }) => {
        r.push(rest);
        if (children) r.push(...flattenCategoryTree(children));
        return r;
    }, []);
};

const formatCriticalData = result => {
    const allTags = sortTags(result.allContentfulTag.nodes);

    const latestVersion = allTags[0].contentful_id;

    const categories = constructCategories(result.allContentfulCategory.edges, '', latestVersion);

    const subCategories = flattenCategories(categories.filter(category => category.subCategories?.length));

    const formattedCategories = categories.map(category => subCategories.find(subCategory => subCategory.id === category.id) ?? category);

    const preCategoryTree = formattedCategories.filter(category => !(subCategories.some(subCategory => subCategory.id === category.id) && category?.page?.length));

    const categoryTree = formatCategoryTree(preCategoryTree.filter(category => !preCategoryTree.some(preCategory => JSON.stringify(preCategory.subCategories)?.includes(category.id))));

    const formattedPages = result.allContentfulPage.edges.map(item => ({
        slug: flattenCategoryTree(categoryTree).find(i => i.id === item.node.id)?.slug || slugToUrl(slugFormatter(item.node, formattedCategories.find(category => category.id === item.node.category?.id)?.slug?.split('/'), latestVersion)),
        ...item.node,
    }));

    return ({
        latestVersion,
        pages: formattedPages.filter(item => item?.title !== getExample()),
        homepages: result.allContentfulHomepage.edges.filter(item => item?.title !== getExample()),
        categories: categoryTree,
    });
};

module.exports = {
    slugify,
    sortTags,
    findDeep,
    isBrowser,
    setLSItem,
    slugToUrl,
    getLSItem,
    getExample,
    tagToVersion,
    slugFormatter,
    preSortTreeView,
    pageURLFormatter,
    removeDuplicates,
    conditionalSpread,
    flattenCategories,
    formatCategoryTree,
    sortAlphabetically,
    formatCriticalData,
    constructCategories,
    escapeRegexCharacters,
};
