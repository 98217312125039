import React, { useEffect, useRef, useState } from 'react';
import { conditionalSpread, rem } from 'clyne-core';
import { useLocation } from '@reach/router';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Menu from '../menu';

import useDevice from '../../hooks/useDevice';
import useClickOutside from '../../hooks/useClickOutside';
import usePopoverInputFocus from '../../hooks/usePopoverInputFocus';

import './index.scss';

const popperConfig = {
    modifiers: (offsetBounding = [0, 0]) => ([
        {
            name: 'offset',
            options: {
                offset: offsetBounding,
            },
        },
        {
            name: 'preventOverflow',
            options: {
                padding: 0,
                rootBoundary: 'window',
            },
        },
        {
            name: 'computeStyles',
            options: {
                gpuAcceleration: false, // true by default
            },
        },
    ]),
    position: [
        'auto-start',
        'auto',
        'auto-end',
        'top-start',
        'top',
        'top-end',
        'right-start',
        'right',
        'right-end',
        'bottom',
        'bottom-start',
        'bottom-end',
        'left-end',
        'left',
        'left-start'
    ],
};

const Popover = props => {
    const {
        menu,
        width,
        header,
        opened,
        onClose,
        content,
        maxItems,
        children,
        position,
        itemHeight,
        selfSizing,
        scrollerRef,
        onClickOutside,
    } = props;

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);

    const options = {
        placement: position,
        strategy: 'absolute',
        modifiers: popperConfig.modifiers([0, 10]),
    };

    const { styles, attributes } = usePopper(referenceElement, popperElement, options);

    const { pathname } = useLocation();
    const [openedState, setOpenedState] = useState(opened);
    const [popperRef, hasPopperClickedOutside] = useClickOutside();
    const [referenceRef, hasReferenceClickedOutside] = useClickOutside();

    const { isMobile } = useDevice();

    useEffect(() => {
        setOpenedState(false);
    }, [pathname]);

    useEffect(() => {
        setOpenedState(opened);
    }, [opened]);

    useEffect(() => {
        if (hasPopperClickedOutside && hasReferenceClickedOutside && openedState && !isMobile) {
            onClickOutside && onClickOutside();
            setOpenedState(false);
            onClose && onClose();
        }
    }, [hasPopperClickedOutside, hasReferenceClickedOutside, onClickOutside, openedState, isMobile]); // eslint-disable-line

    const popoverRef = useRef(null);
    const inputRef = useRef(null);
    const { focused } = usePopoverInputFocus(inputRef);

    return (
        <>
            {React.cloneElement(children, {
                ref: ref => {
                    setReferenceElement(ref);
                    referenceRef.current = ref;
                },
                ...conditionalSpread({
                    onClick: (children.props.onClick) ? children.props.onClick : (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpenedState((val) => !val);
                    },
                }, !props.hasOwnProperty('opened')),
                className: classNames(children.props.className, {
                    'popover-opened': openedState,
                })
            })}
            {openedState && createPortal((
                <div
                    ref={ref => {
                        setPopperElement(ref);
                        popperRef.current = ref;
                    }}
                    {...attributes.popper}
                    style={conditionalSpread({
                        ...conditionalSpread({ width: (width ? rem(width) : referenceRef?.current?.clientWidth) }, !selfSizing || !!width),
                        ...styles.popper,
                        '--top': styles.popper.top,
                        '--rendered-top': `${parseInt(`${styles.popper.top || 0}`) - window.scrollY}px`,
                    }, !isMobile)}
                    className={classNames(
                        `popover-holder`,
                        {
                            'animate-pointer-events': children?.props?.onDoubleClick,
                        }
                    )}
                >
                    {isMobile && (
                        <div
                            role='presentation'
                            className='absolute-splash popover-backdrop'
                            onClick={() => {
                                onClickOutside && onClickOutside();
                                setOpenedState(false);
                                onClose && onClose();
                            }}
                        />
                    )}
                    <ul
                        ref={popoverRef}
                        className='popover-c'
                        style={{
                            ...conditionalSpread({
                                height: popoverRef.current?.clientHeight,
                            }, focused),
                        }}
                    >
                        {!!header && (
                            <li
                                ref={val => {
                                    inputRef.current = val;
                                }}
                                className='po-header border'
                            >
                                {header}
                            </li>
                        )}
                        <li
                            className={classNames(
                                `po-content`,
                                {
                                    'clipped': maxItems && itemHeight && !isMobile,
                                }
                            )}
                            ref={val => !!scrollerRef && scrollerRef(val)}
                            style={conditionalSpread(
                                {
                                    '--maxItems': maxItems,
                                    '--itemHeight': rem(itemHeight),
                                },
                                (maxItems && itemHeight && !isMobile)
                            )}
                        >
                            {typeof content === 'function' ? content({ setOpenedState }) : content}
                            {menu && (
                                <Menu
                                    data={menu}
                                    closePopover={() => setOpenedState(false)}
                                />
                            )}
                        </li>
                    </ul>
                </div>
            ), document.body)}
        </>
    );
};

Popover.defaultProps = {
    position: popperConfig.position[11],
    selfSizing: true,
};

Popover.propTypes = {
    menu: PropTypes.array,
    width: PropTypes.number,
    header: PropTypes.any,
    opened: PropTypes.bool,
    onClose: PropTypes.func,
    content: PropTypes.any,
    maxItems: PropTypes.number,
    children: PropTypes.any,
    position: PropTypes.oneOf(popperConfig.position),
    itemHeight: PropTypes.number,
    selfSizing: PropTypes.bool,
    scrollerRef: PropTypes.func,
    onClickOutside: PropTypes.func,
};

export default Popover;
