import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon';
import Option from '../option';
import Popover from '../popover';

import useDevice from '../../hooks/useDevice';

import './index.scss';

const DropDown = props => {
    const {
        data,
        width,
        label,
        onChange,
        maxItems,
        position,
        selfSizing,
        labelSelector,
        valueSelector,
        defaultSelected,
    } = props;

    const {
        isTablet,
    } = useDevice();

    const getValue = option => option?.[valueSelector];
    const getLabel = option => option?.[labelSelector] === 'function' ? option?.[labelSelector]() : typeof option?.[labelSelector] === 'string' ? option?.[labelSelector] : option?.[labelSelector]?.props?.children;

    const [opened, setOpened] = useState(false);
    const [itemHeight, setItemHeight] = useState(0);

    const [selected, setSelected] = useState();

    useEffect(() => {
        setSelected(defaultSelected !== undefined ? Array.isArray(defaultSelected) || defaultSelected !== Number(defaultSelected) ? defaultSelected : Number(defaultSelected) : null);
    }, [defaultSelected]); // eslint-disable-line

    useEffect(() => {
        opened && onChange && onChange(selected ?? '');
        setOpened(false);
        handleClose();
    }, [selected]); // eslint-disable-line

    const handleClose = () => {
        setOpened(false);
    };

    const isOptionSelected = selectedOption => selected === getValue(selectedOption);

    const onSelect = selectedOption => setSelected(getValue(selectedOption));

    const selectedValue = getLabel(data.filter(option => getValue(option) === selected)?.[0]);

    const value = selectedValue || '';

    const contents = (
        <>
            <span>{[isTablet ? '' : label, value].filter(i => i).join(' ')}</span>
            <Icon
                size={18}
                type='icon-chevron-down'
            />
        </>
    );

    const children = (
        <button
            className='dropdown-c-holder'
            onClick={() => setOpened(val => !val)}
        >
            {isTablet ? (
                <>
                    {!!label && (
                        <span className='dropdown-label'>{label}</span>
                    )}
                    <span className='dropdown-contents'>{contents}</span>
                </>
            ) : contents}
        </button>
    );

    return (
        <Popover
            width={width}
            opened={opened}
            maxItems={maxItems}
            position={position}
            onClose={handleClose}
            selfSizing={selfSizing}
            itemHeight={itemHeight}
            onClickOutside={() => setOpened(false)}
            content={data.map(option => (
                <Option
                    check
                    size='medium'
                    key={getValue(option)}
                    name={getLabel(option)}
                    setItemHeight={setItemHeight}
                    selected={isOptionSelected(option)}
                    onClick={e => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        e.preventDefault();
                        onSelect(option);
                    }}
                />
            ))}
        >
            {children}
        </Popover>
    );
};

DropDown.defaultProps = {
    data: [],
    maxItems: 6,
    selfSizing: false,
    labelSelector: 'name',
    valueSelector: 'id',
};

DropDown.propTypes = {
    data: PropTypes.array,
    width: PropTypes.number,
    label: PropTypes.string,
    maxItems: PropTypes.number,
    position: Popover.propTypes.position,
    onChange: PropTypes.func,
    selfSizing: PropTypes.bool,
    labelSelector: PropTypes.string,
    valueSelector: PropTypes.string,
    defaultSelected: PropTypes.any,
};

export default DropDown;
