import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useRef, useState } from 'react';
import { conditionalSpread } from 'clyne-core';
import Autosuggest from 'react-autosuggest';
import { createPortal } from 'react-dom';

import NoData from '../noData';
import Option from '../option';
import Search from '../search';
import Popover from '../popover';

import useDevice from '../../hooks/useDevice';
import useSharedContext from '../../hooks/useSharedContext';

import { getExample, pageURLFormatter, sortTags } from '../../helpers';

import './index.scss';

const SearchInput = () => {
    const {
        isMobile,
    } = useDevice();

    const {
        searchValue,
        latestVersion,
        setSearchValue,
        setNavigationOpened,
    } = useSharedContext();

    const queryData = useStaticQuery(
        graphql`
            {
                allContentfulPage {
                    edges {
                        node {
                            id
                            category {
                                slug
                                category {
                                    slug
                                    category {
                                        slug
                                    }
                                }
                            }
                            metadata {
                                tags {
                                    contentful_id
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    const [contentRef, setContentRef] = useState(null);
    const [autocompleteData, setAutocompleteData] = useState([]);

    const dummyRef = useRef(null);

    const placeholder = 'Search anything...';

    const onSuggestionSelected = suggestion => {
        if (suggestion?.url) {
            setSearchValue('');
            setAutocompleteData([]);
            setNavigationOpened(false);
            navigate(suggestion.url);
        }
    };

    const onSuggestionsFetchRequested = query => {
        if (query && window.__FLEXSEARCH__) {
            const index = window.__FLEXSEARCH__.en.index;
            const store = window.__FLEXSEARCH__.en.store;
            if (!query || !index) {
                setAutocompleteData([]);
            } else {
                let results = [];

                Object.keys(index).forEach(idx => {
                    results.push(...index[idx].values.search(query));
                });

                results = Array.from(new Set(results));

                const nodes = store.filter(node => (results.includes(node.id) ? node : null)).filter(({ node }) => node.title !== getExample()).map(({ node }) => {
                    const match = queryData.allContentfulPage.edges.find(item => item.node.id === node.id)?.node;

                    const url = pageURLFormatter({
                        ...(match || {}),
                        ...node,
                    }, latestVersion);

                    const tag = (match?.metadata?.tags?.length ? sortTags(match.metadata.tags) : [latestVersion])[0];

                    const version = tag.contentful_id === latestVersion || !tag?.contentful_id ? '' : `v${tag?.contentful_id}`;

                    return {
                        ...node,
                        url,
                        tags: match?.metadata?.tags,
                        title: [version, node.title].filter(i => i).join(' - '),
                    };
                });

                setAutocompleteData(nodes);
            }
        }
    };

    const renderSuggestion = (suggestion, isHighlighted) => {
        const flatMap = JSON.parse(suggestion.content)?.content?.filter(item => item?.nodeType === 'paragraph')?.flatMap(item => item?.content);
        const information = flatMap?.find(item => item?.nodeType === 'text' && item?.value)?.value?.trim();

        return (
            <Option
                size='big'
                key={suggestion.id}
                name={suggestion.title}
                highlighted={isHighlighted}
                information={information}
            />
        );
    };

    const inputProps = {
        value: searchValue || '',
        onChange: (_, { newValue }) => {
            setSearchValue(newValue);
        },
    };

    const renderInputComponent = (props) => {
        const {
            ref,
            value,
            onBlur,
            onFocus,
            onChange,
            onKeyDown,
        } = props;

        return (
            <Popover
                selfSizing={false}
                scrollerRef={(ref) => setContentRef(ref)}
                {...conditionalSpread(
                    {
                        opened: !!autocompleteData?.length,
                    },
                    !isMobile
                )}
                {...conditionalSpread(
                    {
                        header: (
                            <Search
                                value={value}
                                onBlur={onBlur}
                                onFocus={onFocus}
                                onChange={onChange}
                                appearance='inline'
                                onKeyDown={onKeyDown}
                                ref={(val) => ref(val)}
                                placeholder={placeholder}
                            />
                        ),
                        content: !autocompleteData.length && (
                            <NoData
                                size='small'
                                type='search'
                                subTitle='try anything'
                                title='Type to search...'
                            />
                        ),
                    },
                    isMobile
                )}
            >
                <Search
                    value={value}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChange={onChange}
                    readOnly={isMobile}
                    ref={val => ref(val)}
                    onKeyDown={onKeyDown}
                    placeholder={placeholder}
                />
            </Popover>
        );
    };

    const renderSuggestionsContainer = (containerProps, children) => {
        containerProps.ref(contentRef);
        return contentRef && children && createPortal(children, contentRef);
    };

    return (
        <div className='search-input-c-holder'>
            <Autosuggest
                inputProps={inputProps}
                suggestions={autocompleteData}
                alwaysRenderSuggestions={isMobile}
                highlightFirstSuggestion={!isMobile}
                focusInputOnSuggestionClick={false}
                getSuggestionValue={(suggestion) => suggestion.title}
                renderInputComponent={(props) => renderInputComponent(props)}
                onSuggestionsClearRequested={() => setAutocompleteData([])}
                onSuggestionSelected={(_, { suggestion }) => onSuggestionSelected(suggestion)}
                onSuggestionsFetchRequested={({ value }) => onSuggestionsFetchRequested(value)}
                renderSuggestion={(suggestion, { isHighlighted }) => renderSuggestion(suggestion, isHighlighted)}
                renderSuggestionsContainer={({ containerProps, children }) => renderSuggestionsContainer(containerProps, children)}
            />
            <div className='absolute-hidden' ref={dummyRef} />
        </div>
    );
};

export default SearchInput;
